html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  font-family: 'Open Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 16px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: 'Open Sans';
  src: local('Open Sans'), url(./assets/fonts/OpenSans.ttf) format('truetype');
}

html,
body,
#root {
  min-height: 100vh;
  -webkit-overflow-scrolling: touch;
}

#modal {
  min-height: 100%;
}

#root {
  display: flex;
  flex-direction: column;
}

button,
input,
optgroup,
select,
textarea {
  font-family: inherit;
  font-size: 1rem;
}

* {
  box-sizing: border-box;
}
